import styles from './styles.module.scss';

function App() {
  return (
    <div className={styles.homeContainer}>
      Page under construction...
    </div>
  );
}

export default App;
